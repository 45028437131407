var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('router-link',{attrs:{"to":'/estadisticas'}},[_vm._v("Estadísticas")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v(_vm._s(_vm.itemCategoria.nombre))])],1),_c('template',{slot:"area-titulo"},[_c('h3',{staticClass:"text-center"},[_vm._v("Estadísticas - "+_vm._s(_vm.itemCategoria.nombre))])])],2),_c('div',{staticClass:"container",attrs:{"id":"content-page"}},[_c('div',{staticClass:"row justify-content-between"},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"col-lg-4 col-md-4 col-sm-6"},[_c('div',{staticClass:"box-publicacion mt-4",style:({
            '--background-color-terciario':
              _vm.config.disenio.datos.color_terciario,
          }),attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"portada"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.imagen),expression:"item.imagen"}],staticClass:"img-fluid img-responsive",attrs:{"alt":item.titulo,"title":item.titulo},on:{"error":_vm.replaceByDefault}})]),_c('div',{staticClass:"body p-2 h-100"},[_c('small',{staticClass:"date"},[_c('i',{staticClass:"icon"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z","fill":"#666666"}})])]),_vm._v(" "+_vm._s(item.publicado)+" ")]),_c('p',{staticClass:"font-weight-bold"},[_c('router-link',{staticClass:"text-secondary",style:({
                  '--color-secondary': _vm.config.disenio.datos.color_secundario,
                }),attrs:{"to":'/estadistica/' + _vm.categoria + '/' + item.url}},[_vm._v(" "+_vm._s(item.titulo)+" ")])],1),_c('div',{staticClass:"item-descripcion"},[_c('small',{domProps:{"innerHTML":_vm._s(item.descripcion)}})])]),_c('div',{staticClass:"card-footer p-2"},[_c('small',{staticClass:"text-muted"},[_c('router-link',{staticClass:"btn btn-sm btn-fiscalia",style:({
                  '--background-color-btn':
                    _vm.config.disenio.datos.color_primario,
                }),attrs:{"to":'/estadistica/' + _vm.categoria + '/' + item.url}},[_vm._v(" Leer más "),_c('i',{staticClass:"icon"},[_c('svg',{attrs:{"width":"12","height":"18","viewBox":"0 0 12 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.7008 8.16086L5.60348 0.178257C5.55668 0.117395 5.49696 0.083313 5.43402 0.083313H4.00571C3.88629 0.083313 3.83141 0.307284 3.92179 0.424138L9.57369 7.8249H0.129112C0.0581006 7.8249 0 7.91255 0 8.01966V9.48034C0 9.58746 0.0581006 9.6751 0.129112 9.6751H9.57207L3.92018 17.0759C3.8298 17.1952 3.88467 17.4167 4.0041 17.4167H5.48082C5.51149 17.4167 5.54215 17.3996 5.56475 17.368L11.7008 9.33914C11.7567 9.26585 11.8015 9.17534 11.8322 9.0737C11.8629 8.97207 11.8788 8.86169 11.8788 8.75C11.8788 8.63831 11.8629 8.52793 11.8322 8.4263C11.8015 8.32466 11.7567 8.23415 11.7008 8.16086Z","fill":"white"}})])])])],1)])])])}),0),_c('div',{staticClass:"mt-5 mb-5 text-center row",attrs:{"id":"navegacion"}},[_c('b-pagination-nav',{staticClass:"my-0",style:({
          '--background-color-secondary':
            _vm.config.disenio.datos.color_secundario,
          '--background-color-tertiary': _vm.config.disenio.datos.color_terciario,
        }),attrs:{"use-router":"","link-gen":_vm.linkGen,"number-of-pages":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }